<template>
  <div class="emailConfirm">
    <div class="topBar stickTop">
      <div class="logoIcon">
        <img src="images/logo-white.png" alt="" />
      </div>
      <div class="logoName">
        <span class="name1">Hotspex</span>
        <span class="name2">STUDIO</span>
      </div>
    </div>
    <div class="centerContent">
      <section class="formContainer">
        <!-- confirmation success-->
        <div v-if="status === 1 || status === 2">
          <h2>Verification Complete</h2>
          <p>
            Congratulation! Your account has been successfully confirmed. Please
            <router-link :to="{ name: 'login' }" class="blueTextLink"
              >login</router-link
            >
            to access your Hotspex Studio Dashboard.
          </p>
        </div>
        <!-- confirmation fail -->
        <div v-if="status === 3 || status === 0">
          <h2>Oops...</h2>
          <p>
            We've run into a problem processing your request. Please try again
            or contact support at
            <a href="mailto:support@hotspexstudio.com" target="_blank"
              >support@hotspexstudio.com</a
            >.
          </p>
        </div>
        <!-- in confirmation -->
        <div v-if="status === -1">
          <p>Verification in process ...</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
export default {
  data() {
    return {
      status: -1,
    };
  },
  created() {
    const params = this.$route.query;
    console.log(params);

    const verifyPromise = new Promise((resolve) => {
      wsUtils.VerifyEmail(resolve, { VerifyId: params.token });
    });

    verifyPromise.then((data) => {
      // console.log("VerifyEmail", data);
      this.status = data.Status;
    });
  },
};
</script>

<style lang="scss" scoped>
.emailConfirm {
  background: url("../../public/images/logBG5.jpg") center no-repeat;
  background-color: #000;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  background-attachment: fixed;

  display: grid;
  grid-template-rows: auto 1fr;
}

.topBar {
  background-color: var(--hsred);
  height: 75px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;

  .logoIcon {
    background-color: var(--hsgrey);
    height: 100%;
    padding: 10px;

    img {
      max-height: 100%;
      display: block;
      margin: 0;
    }
  }

  .logoName {
    color: #fff;

    display: flex;
    flex-direction: column;
    padding-left: 10px;
    text-align: left;

    .name1 {
      font-size: 26px;
      font-weight: 500;
      letter-spacing: 2px;
    }

    .name2 {
      font-size: 18px;
      letter-spacing: 3px;
      font-weight: 300;
    }
  }
}

.formContainer {
  padding: 2em;
  text-align: center;
  line-height: 2em;
}
</style>
